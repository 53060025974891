<template>
  <div>
    <v-simple-table data-testid="all-user-list">
      <thead>
        <tr>
          <th width="1%"></th>
          <th class="text-left">Name</th>
          <th class="text-left">Email</th>
          <th class="text-left">Plan</th>
          <th class="text-right">Joined Date</th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <template>
          <tr
            v-for="user in users"
            :key="user.id"
            class="clickable new-tab-link-row"
          >
            <td class="py-3">
              <user-avatar class="mx-auto" :avatar="user.avatar" size="36" />

              <router-link
                :to="{
                  name: 'user.profile',
                  params: { id: user.id },
                }"
                class="row-link"
              />
            </td>
            <td>
              {{ user.full_name }}

              <router-link
                :to="{
                  name: 'user.profile',
                  params: { id: user.id },
                }"
                class="row-link"
              />
            </td>
            <td>
              {{ user.email }}

              <router-link
                :to="{
                  name: 'user.profile',
                  params: { id: user.id },
                }"
                class="row-link"
              />
            </td>
            <td>
              <span v-if="user.default_program">
                {{ user.default_program.name }}
                {{ user.default_program.package_name }}
              </span>

              <router-link
                :to="{
                  name: 'user.profile',
                  params: { id: user.id },
                }"
                class="row-link"
              />
            </td>
            <td class="text-right">
              <span v-if="user.default_program">
                {{ user.created_at | formatDate }}
              </span>

              <router-link
                :to="{
                  name: 'user.profile',
                  params: { id: user.id },
                }"
                class="row-link"
              />
            </td>
          </tr>
        </template>
      </tbody>

      <table-skeleton-loader v-if="loading" column="5" row />
    </v-simple-table>

    <no-list v-if="!users.length && !loading" details="No Available Users" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import UserAvatar from '@/components/UserAvatar'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'UsersListTable',

  directives: {
    infiniteScroll,
  },

  components: {
    TableSkeletonLoader,
    UserAvatar,
    NoList,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    disableListScroll() {
      const params = this.links.next ? this.$jsonFromUrl(this.links.next) : 0
      return this.loading || this.meta.current_page >= params['page']
    },
  },

  methods: {
    openUserProfile(user) {
      this.$router.push({ name: 'user.profile', params: { id: user.id } })
    },

    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },
  },
}
</script>
