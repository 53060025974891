var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{attrs:{"data-testid":"all-user-list"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"1%"}}),_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Email")]),_c('th',{staticClass:"text-left"},[_vm._v("Plan")]),_c('th',{staticClass:"text-right"},[_vm._v("Joined Date")])])]),_c('tbody',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":_vm.disableListScroll}},[_vm._l((_vm.users),function(user){return _c('tr',{key:user.id,staticClass:"clickable new-tab-link-row"},[_c('td',{staticClass:"py-3"},[_c('user-avatar',{staticClass:"mx-auto",attrs:{"avatar":user.avatar,"size":"36"}}),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'user.profile',
                params: { id: user.id },
              }}})],1),_c('td',[_vm._v(" "+_vm._s(user.full_name)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'user.profile',
                params: { id: user.id },
              }}})],1),_c('td',[_vm._v(" "+_vm._s(user.email)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'user.profile',
                params: { id: user.id },
              }}})],1),_c('td',[(user.default_program)?_c('span',[_vm._v(" "+_vm._s(user.default_program.name)+" "+_vm._s(user.default_program.package_name)+" ")]):_vm._e(),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'user.profile',
                params: { id: user.id },
              }}})],1),_c('td',{staticClass:"text-right"},[(user.default_program)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(user.created_at))+" ")]):_vm._e(),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'user.profile',
                params: { id: user.id },
              }}})],1)])})],2),(_vm.loading)?_c('table-skeleton-loader',{attrs:{"column":"5","row":""}}):_vm._e()],1),(!_vm.users.length && !_vm.loading)?_c('no-list',{attrs:{"details":"No Available Users"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }